:root {
  --color-primary: rgb(239, 125, 0);
  --color-primary--transparency: rgb(239, 125, 0, 0.8);
  --color-secondary: rgb(251, 186, 0);
  --color-background-button: var(--color-secondary);
  --color-background-button--hover: var(--color-primary);
  --color-background-footer: var(--color-primary);
  --color-background-main: rgb(255, 255, 255);
  --color-link: rgb(34, 84, 153);
  --color-text-button: rgb(255, 255, 255);
  --color-text-button--hover: rgb(255, 255, 255);
  --color-text-footer: #fff;
  --color-text-main: rgb(20, 20, 20);
  --font-family-main: Open Sans, system-ui, sans-serif;
  --font-weight-header: 700;
  --text-transform-menu: none;
}

.site-header {
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3);
  margin-block-end: var(--s4);
}

.site-header + .dmp-toolbar {
  margin-block: calc(-1 * var(--s4)) var(--s4);
}

/* h1,
h2 {
  font-weight: 800;
} */

#site-navigation {
  margin-inline-start: auto;
}
